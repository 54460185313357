<template>
    <div>
        <partners
            :buttonPanelDetails="buttonPanelDetails"
            :counterDetails="counterDetails"
            :filterObject="filterObject"
            :menuwidth="menuwidth"
            :partnerHeader="partnerHeader"
            :partnerRow="partnerRow"
            :totalItems="totalData"
            :pageSize="pageSize"
            :page="page"
            :partnerListProgress="partnerListProgress"
            :paginationComponentKey="paginationComponentKey"
             @rowEditActionButton="rowEditActionButton"
            @rowDeleteActionButton="rowDeleteActionButton"
            @onClickPanelButton="onClickPanelButton"
            @handleSortEvent="handleSortEvent"
            @paginateTable="paginateTable"
            @searchFilter="searchFilter"
            @clearTextFilter="clearTextFilter"
            @clearAllFilters="clearAllFilters"
        >
        </partners>
        <dialog-popup
            :toggleDialog="toggleDialog"
            :title="dialogTitle"
            :sub-title="subTitle"
            :selectedAction="selectedAction"
            checkboxLabel="Yes, delete this partner."
            deleteLabel="partner"
            @cancel="closeDialogPopup"
            @emitDeletePopup="deletePopupModal"
        >
        </dialog-popup>
        <notification-component
            v-if="showNotification"
            :notificationMessage="notificationMessage"
            :notificationType="notificationType"
            :keyType="notificationKey"
            :dismissDelay="5000"
            @close="showNotification = false"
        ></notification-component>
    </div>
</template>

<script>
import get from "lodash/get";
import Partners from "../components/Partners";
import { mapGetters, mapState } from "vuex";
import store from "@/store";
import { PARTNER_ACTIONS } from "@/store/modules/partner/contants";
import DialogPopup from "@/components/common_components/DialogPopup.vue";
import NotificationComponent from "../../../ui_component/notificationComponent.vue";

export default {
    beforeCreate() {
        this.$store = store;
    },
    components: {
        Partners,
        DialogPopup,
        NotificationComponent,
    },
    data() {
        return {
            counterDetails: {},
            filterObject: [],

            buttonPanelDetails: [
                {
                    id: "AddButtonId",
                    buttonName: "Create Partner",
                    iconName: "plus",
                    iconColor: "defaultColor",
                    isDisabled: false,
                    showVerticalLine: false,
                    isText: false,
                },
            ],
            partnerHeader: [
                {
                    fieldName: "vendorname",
                    text: "Name",
                    width: "90%",
                    sortable: true,
                    sorted: 1,
                },
                {
                    fieldName: "actions",
                    text: "Actions",
                    width: "10%",
                    align: "right",
                },
            ],
            partnerRow: [],
            selectMultiRowData: [],
            //sorting
            sortType: true,
            sortOrder: -1,
            sortColIndex: 1,
            orderByColumn: "vendorname",
            totalData: 0,
            page: 1,
            pageSize: 10,
            searchPartner: "",
            //dialog popup
            toggleDialog: false,
            selectedAction: "",
            subTitle: "",
            dialogTitle: "",
            //Notification
            showNotification: false,
            notificationMessage: "",
            notificationType: "",
            paginationComponentKey:0,

            vendorid:0,
        };
    },
    created() {},
    async mounted() {
        this.commonFunction();
    },
    props: {
        menuwidth: {
            type: String,
        },
    },

    computed: {
        ...mapGetters(["cachedDispatch"]),
        ...mapState({
            partnerList(state) {
                return get(state, "partner.partners.partners", []);
            },
            partnerListProgress(state) {
                return get(state, "partner.partners.__loading__", false);
            },
            partnerDelete(state) {
                return get(
                    state,
                    "partner.deletePartners.deletePartners",
                    []
                );
            },
            partnerDeleteError(state) {
                return get(state, "partner.deletePartners.__error__", null);
            },
        }),
    },
    methods: {
        onClickPanelButton(val) {
            if (val === "Create Partner") {
                this.createPartner();
            }
        },

        createPartner() {
            this.$router.push({
                name: "Data Dissemination Create Partner",
            });
        },

        // evet for getting all schedule list
        async commonFunction() {
            var data = {
                page: this.page,
                pageSize: this.pageSize,
                searchPartner: this.searchPartner,
                orderByColumn: this.orderByColumn,
                isAscending: this.sortType,
            };
            await this.$store.dispatch(PARTNER_ACTIONS.FETCH_PARTNER_LIST, {
                data,
            });
            let response = this.partnerList;
            this.partnerRow = response.list;
            this.totalData = response.total;
        },

        // event for search panel
        searchFilter(searchText) {
            this.page = 1;
            this.searchPartner = searchText;
            this.commonFunction();
            this.paginationComponentKey+=1;
        },

        // event for clear text field
        clearTextFilter() {
            this.searchPartner = "";
            this.page = 1;
            this.commonFunction();
            this.paginationComponentKey+=1;
        },

         // event for clear all filter function
        clearAllFilters() {
            this.searchPartner = "";
            this.clearTextFilter();
        },

        handleSortEvent(colIndex, colName) {
            this.page = 1;
            this.executeSortingLogicForMdsTable(colIndex);
            this.orderByColumn = colName;
            this.sortOrder == 1
                ? (this.sortType = true)
                : (this.sortType = false);
            this.commonFunction();
            this.paginationComponentKey+=1;
        },

        executeSortingLogicForMdsTable(colIndex) {
            this.sortOrder = this.sortOrder === 0 ? 1 : -this.sortOrder;

            if (this.sortColIndex !== null && this.sortColIndex !== colIndex) {
                this.$set(this.partnerHeader[this.sortColIndex], "sorted", 0);
                this.sortOrder = -1;
            }

            this.sortColIndex = colIndex;
            this.$set(this.partnerHeader[colIndex], "sorted", this.sortOrder);
        },

        // event for pagination
        paginateTable(arg) {
            this.page = arg.page;
            this.pageSize = arg.pageSize;
            this.commonFunction();
        },

        // row-actions
         rowEditActionButton(value) {
            this.$router.push(`/data-dissemination/partners/create?vendorid=${value.vendorid}`);
        },

        rowDeleteActionButton(value) {
            this.subTitle = `This action cannot be undone. The partner and all related delivery methods will be deleted for all users with access.`;
            this.dialogTitle = "Delete this partner?";
            this.selectedAction = "Delete";
            this.toggleDialog = true;
            this.vendorid = value.vendorid;
        },

        async deletePopupModal() {
            this.toggleDialog = false;
            let partnerIds = this.vendorid;
            await this.$store.dispatch(
                PARTNER_ACTIONS.FETCH_DELETE_PARTNER_LIST,
                {partnerIds}
            );
            let response = this.partnerDelete;
            const obj = {
                response,
                ntfnMsg: "The partner has been deleted.",
                ntfnType: "informational",
                ntfnKey: "informational-example",
                ntfnfailureMsg: this.partnerDeleteError,
            };
            this.notificationResponse(obj);
        },

        closeDialogPopup() {
            this.toggleDialog = false;
        },

        // notification event
        notificationResponse({
            response,
            ntfnMsg,
            ntfnType,
            ntfnKey,
            ntfnfailureMsg,
        }) {
            if (response.statuscode == 200) {
                this.notificationMessage = ntfnMsg;
                this.notificationType = ntfnType;
                this.showNotification = true;
                this.notificationKey = ntfnKey;
                this.showLoader = false;
                this.commonFunction();
            } else {
                this.showLoader = false;
                this.notificationKey = "error-default";
                this.notificationMessage = ntfnfailureMsg;
                this.notificationType = "error";
                this.showNotification = true;
            }
        },

    },
};
</script>

