<template>
    <div :class="{isFilterOpen: checkFilterOpen}">
        <loader-component v-if="partnerListProgress"></loader-component>
        <mds-layout-grid class="partner">
            <mds-row class="partner_table_list">
                <mds-col :cols="12" class="partner_column">
                <div
                    :class="[
                        { showFilterLeftBlock: showFilterPanel },
                        'leftBlock',
                    ]"
                    :style="cssVariables"
                >
                    <div class="scheduleHeaderSection">
                        <div>
                            <h2 class="partner-header">Partners</h2>
                        </div>
                        <div
                            class="headerActionButton"
                            style="margin-top: 25px"
                        >
                            <header-button-panel
                                :buttonDetails="buttonPanelDetails"
                                :counterDetails="counterDetails"
                                @panelButtonClicked="onClickPanelButton"
                            ></header-button-panel>
                        </div>
                    </div>
                    <div class="partnerTable">
                        <div class="partner_table">
                            <!-- list  -->
                            <div class="partner_table_section">
                            <mds-table show-sortable v-if="partnerLength > 0">
                                <mds-thead>
                                    <mds-th
                                        v-for="(header, index) in partnerHeader"
                                        :key="index"
                                        :sortable="header.sortable"
                                        :sorted="header.sorted"
                                        :width="header.width"
                                        :right-aligned="
                                            header.align === 'right'
                                        "
                                        @mds-th-sort-changed="
                                            handleSortEvent(
                                                index,
                                                header.fieldName,
                                                $event
                                            )
                                        "
                                    >
                                        {{ header.text }}
                                    </mds-th>
                                </mds-thead>
                                <mds-tbody>
                                    <mds-tr
                                        v-for="(row, index) in partnerRow"
                                        :key="index"
                                        :checked="row.checked"
                                        :multiselection-label="row.name"
                                        @mds-tr-multiselection-changed="
                                            handleRowSelectEvent(
                                                index,
                                                $event,
                                                row
                                            )
                                        "
                                    >
                                        <mds-td
                                            v-for="(header, i) in partnerHeader"
                                            :key="i"
                                            :right-aligned="
                                                header.align === 'right'
                                            "
                                        >
                                            <template
                                                v-if="
                                                    header.fieldName ===
                                                    'actions'
                                                "
                                            >
                                                <span
                                                    ><mds-button
                                                        v-on:click="
                                                            rowEditActionButton(
                                                                row
                                                            )
                                                        "
                                                        :disabled="
                                                            !row.isedit
                                                        "
                                                        class="actionButton"
                                                        icon="pencil"
                                                        variation="icon-only"
                                                        type="button"
                                                    ></mds-button
                                                ></span>
                                                <span
                                                    ><mds-button
                                                        @click="
                                                            rowDeleteActionButton(
                                                                row
                                                            )
                                                        "
                                                        :disabled="!row.isdelete 
                                                        "
                                                        class="actionButton"
                                                        icon="trash"
                                                        variation="icon-only"
                                                        type="button"
                                                    ></mds-button
                                                ></span>
                                            </template>
                                            <template
                                                v-else
                                                @click="
                                                    rowEditActionButton(row)
                                                "
                                            >
                                                {{ row[header.fieldName] }}
                                            </template>
                                        </mds-td>
                                    </mds-tr>
                                </mds-tbody>
                            </mds-table>
                            
                            <mds-empty-state
                                v-if="partnerRow == undefined || !partnerRow.length"
                                class="no-results-message"
                                size="medium"
                                title="No results matched"
                                message="We weren't able to match any results with your current selections. Please adjust your filter criteria and try again."
                            ></mds-empty-state>
                            </div>
                        </div>
                        
                            <mds-pagination
                                :class="[
                                    showFilterPanel === true
                                        ? 'move-pagination-show-info'
                                        : '',
                                    'pagination-component',
                                ]"
                                v-if="partnerLength > 0"
                                :key="paginationComponentKey"
                                show-items-info
                                show-items-select
                                :total-items="totalItems"
                                :pageSize="pageSize"
                                :pageSizes="[10, 20, 50]"
                                @mds-pagination-page-changed="paginateTable"
                                style="margin-top: 10px; margin-right: 3px"
                            ></mds-pagination>
                    </div>
                </div>
                </mds-col>
                <div
                    :class="[
                        { showFilterRightBlock: showFilterPanel },
                        'rightBlock',
                    ]"
                    :style="cssVariables"
                >
                    <div class="filterPanel">
                        <transition-group name="fade" class="group-4">
                            <filter-panel
                                :key="checkBoxFilterKey"
                                :filterData="filterObject"
                                @filterEvent="searchFilter"
                                @multiSelectFilterChange="
                                    multiSelectFilterChange
                                "
                                @hideFilterPanel="closeFilterPanel"
                                :isFilterPanelOpen="showFilterPanel"
                                searchInputPlaceHolder="Search partners"
                                @showFilterPanel="openFilterPanel"
                                :showSearchInput="true"
                                @clearInputFilter="clearTextFilter"
                                @clearAllFilters="clearAllFilters"
                                @date="date"
                            ></filter-panel>
                        </transition-group>
                    </div>
                </div>
                
            </mds-row>
        </mds-layout-grid>
    </div>
</template>

<script>
import { MdsLayoutGrid, MdsRow, MdsCol } from "@mds/layout-grid";
import FilterPanel from "../../../common_components/FilterPanel.vue";
import LoaderComponent from "../../../ui_component/loaderComponent.vue";
import HeaderButtonPanel from "../../../common_components/HeaderButtonPanel.vue";
import MdsEmptyState from "@mds/empty-state";
import {
    MdsTable,
    MdsThead,
    MdsTh,
    MdsTr,
    MdsTbody,
    MdsTd,
} from "@mds/data-table-4.0.2";
import { MdsButton } from "@mds/button";
import MdsPagination from "@mds/pagination";


export default {
    name: "partners",
    components: {
        MdsLayoutGrid,
        MdsRow,MdsCol,
        MdsTable,
        MdsThead,
        MdsTh,
        MdsTr,
        MdsTbody,
        MdsTd,
        FilterPanel,
        LoaderComponent,
        HeaderButtonPanel,
        MdsEmptyState,
        MdsButton,
        MdsPagination
    },
    data() {
        return {
            showFilterPanel: false,
            isFilterPanelOpen: true,
            checkBoxFilterKey: 0,
            //pagination
            firstItem: "",
            lastItem: "",
            checkFilterOpen: false
        };
    },

    props: {
        buttonPanelDetails:  {
            type: Array,
            default: () => [],
        },
        counterDetails: {
            type: Object,
            default: () => {},
        },
        filterObject:  {
            type: Array,
            default: () => [],
        },
        menuwidth: {
            type: String,
            default: "",
        },
        partnerRow: {
            type: Array,
        },
        partnerHeader: {
            type: Array,
            default: () => [],
        },
        totalItems: {
            type: Number,
            default: 0,
        },
        pageSize: {
            type: Number,
            default: 0,
        },
        page: {
            type: Number,
            default: 0,
        },
        partnerListProgress: {
            type: Boolean,
            default: false,
        },
        paginationComponentKey: {
            type: Number,
            default: 0,
        },
    },
    computed: {
      partnerLength() {
            if (this.partnerRow !== undefined) {
                if (this.partnerRow.length > 0) {
                    return this.partnerRow.length;
                } else {
                    return 0;
                }
            } else {
                return 0;
            }
        },

        cssVariables() {
            return {
                "--menuWidth": this.menuwidth,
            };
        },
    },
    methods: {
        onClickPanelButton(event) {
            this.$emit("onClickPanelButton", event);
        },
        rowEditActionButton(value) {
            this.$emit("rowEditActionButton", value);
        },

        rowDeleteActionButton(value) {
            this.$emit("rowDeleteActionButton", value);
        },
        //Start Filter Panel
        openFilterPanel() {
            this.checkFilterOpen = true;
            this.showFilterPanel = true;
            this.$emit("openFilterPanel");
        },

        closeFilterPanel() {
            this.checkFilterOpen = false;
            this.showFilterPanel = false;
        },

        clearTextFilter() {
            this.$emit("clearTextFilter");
        },

        clearAllFilters() {
            this.$emit("clearAllFilters");
        },

        searchFilter(searchText) {
            this.$emit("searchFilter", searchText);
        },

        date(date, type) {
            this.$emit("date", date, type);
        },

        multiSelectFilterChange(event, value, headerTypeDetails) {
            this.$emit(
                "multiSelectFilterChange",
                event,
                value,
                headerTypeDetails
            );
        },
        //End Filter Panel
        handleSortEvent(colIndex, colName) {
            this.$emit("handleSortEvent", colIndex, colName);
        },

        //pagination
        paginateTable(arg) {
            this.firstItem = arg.firstItem;
            this.lastItem = arg.lastItem;
            this.$emit("paginateTable", arg);
        },

        paginationKeyUpdate() {
            this.paginationComponentKey += 1;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/styles.scss";

.isFilterOpen {width: 82%}

.partner-header {
    margin-top: 16px;
    @include mds-level-2-heading($bold: false);
}

.partnerTable {
    margin-top: 13px;

    .partner_table {
        height: auto;
        max-height: 69vh;
        overflow: auto;
    }
}

.leftBlock {
    max-width: 94%;
    position: relative;
    overflow: hidden;
    margin-left: 16px;
    margin-right: 71px;
}

.rightBlock {
    width: 55px;
    padding: 0;
    margin-left: calc(100vw - 55px - var(--menuWidth));
    min-height: 100vh;
    border: 1px solid #979797;
    border-top: 1px solid #cccccc;
    position: fixed;
    z-index: 1;
    background-color: white;
}

.showFilterRightBlock {
    width: 292px;
    margin-left: calc(100vw - 292px - var(--menuWidth));
    position: fixed;
    z-index: 1;
}

.showFilterLeftBlock {
    max-width: 96%;
    margin-left: 16px;
    margin-right: 71px;
}

.actionButton {
    margin-left: 5px;
    margin-right: 5px;
}

.no-results-message {
    margin: auto;
    min-width: 50%;
    margin-top: 16px;
}

.move-pagination-show-info::v-deep .mds-pagination__show-items___VueMDS3Demo {
    margin-right: calc(292px - 55px);
}

.pagination-component {
    position: fixed;
    width: calc(100vw - 88px - var(--menuWidth));
    bottom: 0;
}

.partner_table_list{
    margin: 0;
    padding: 0;
}

.partner_column{
    margin: 0px;
    padding: 0px;
}

.partner_table_section{
    min-height: 64vh;
}
</style>
